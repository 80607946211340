
















































































import { formatRupiah } from "@/apps/core/modules/utils/text";
import { defineComponent } from "@vue/composition-api";
import { toUserDateTimeFormat } from "@/apps/core/modules/utils/datetime";
import router from "@/router";
// import { setRefreshRequest } from "@/apps/pinjaman/modules/stores/cicilan";

export default defineComponent({
  name: "PengajuanListBox",
  props: {
    pengajuan: { type: Object, required: true },
  },
  components: {
    StatusTag: () => import("@/apps/core/components/StatusTag.vue"),
  },
  setup(props) {
    const goToRiwayat = () =>
      // setRefreshRequest("pengajuan-list");
      router.push({
        name: "pinjaman-cicilan",
        params: { id: props.pengajuan.pinjaman.id },
      });
    {
      {
        props.pengajuan.pinjaman;
      }
    }
    let angsuranList = null;
    if (props.pengajuan.pinjaman) {
      angsuranList = JSON.parse(
        props.pengajuan.pinjaman.angsuran ?? "[]"
      );
    }
    return {
      // Data
      angsuranList,
      detailUrl: "/pengajuan",
      formatRupiah,

      // Method
      goToRiwayat,
      toUserDateTimeFormat,
    };
  },
});
